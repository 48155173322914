import React from "react"
import classnames from 'classnames';
import { connect } from "react-redux"
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4'
import withStyles from 'react-jss'
import {
  Button, Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, TabContent,
  TabPane, CardImg, Row, Container, Spinner, Modal, ModalFooter, ModalBody, Alert
} from 'reactstrap';
import { FaArrowLeft, FaArrowRight, FaPrint } from "react-icons/all"
import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-intl"

import GCCLogo from '../assets/img/gcc-logo-small.png'
import * as actions from "../store/actions"
import MapService, { createEmptyMap } from '../utils/mapService'
import printMap from '../utils/printMapService'
import FeatureIdentify from '../utils/identifyService'
import Layout from "../containers/Layout"
import SEO from "../components/seo"
import axios from "../api/axios"
import NoImg from "../images/no_img.png"
import Switcher from '../components/maps/LayersSwitcher'
import BaseMapSwitcher from '../components/maps/BaseMapSwitcher'
import CloseLogo from '../assets/img/close.png'
import 'ol/ol.css'
import '../assets/css/custom-ol-layerswitcher.css'
import '../assets/css/maps_page.css'
import '../assets/css/loading-spinner.css'

const styles = {
  // mapDiv: {
  //   height: '600px',
  //   width: '100%',
  //   paddingTop: '50px',
  //   marginLeft: 'auto',
  //   marginRight: 'auto'
  // },
  mapContainer: {
    padding: 20,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  staticMapContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  navColor: {
    backgroundColor: '#56c9c7'
  },
  propDiv: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  propsTable: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    maxHeight: '474px',
    paddingBottom: '30px',
  },
  tableButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'absolute',
    left: '10px',
    right: '10px',
    backgroundColor: 'rgb(239, 250, 251, 0.6)',
    padding: '10px 0',
  },
  navItem: {
    cursor: 'pointer',
  }
}
register(proj4)

class MapsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map: null,
      featureIdentifyLoading: false,
      activeFeature: 0,
      activeTab: '1',
      featureIdentifyResult: [],
      staticMaps: [],
      staticMapsLoading: false,
      tilesLoading: false,
      mobileModalIsOpen: false,
      isPrinting: false,
      showErrorAlert: false
    }
    this.tilesLoadingArray = []
  }

  componentDidMount() {
    var activeTabParam = new URL(window.location.href).searchParams.get("type") != "static" ? "interactive" : "static";
    navigate("/maps/?type=" + activeTabParam)
    this.setState({
      activeTab: activeTabParam === "interactive" ? '1' : activeTabParam === "static" ? '2' : '1',
      tilesLoading: true
    })
    const map = createEmptyMap()
    this.mapService = new MapService(map, this.tileLoadStart, this.tileLoadEnd)
    document.documentElement.classList.remove("nav-open")
    this.fetchMapExplorerConfig(this.props.intl.locale)
    map.setTarget(document.getElementById('map'))
    map.on('singleclick', (evt) => {
      this.setState({
        featureIdentifyLoading: true,
        activeFeature: 0,
        featureIdentifyResult: [],
      }, () => this.identify(evt))
    })
    const isMobile = window.innerWidth < 769
    this.setState({ map, isMobile });
    if (this.props.selectedRegion !== null)
      this.fetchStaticMaps()
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedRegion !== prevProps.selectedRegion) {
      this.fetchStaticMaps()
    }
  }

  componentWillReceiveProps(nextProps) {
    const { mapExplorerConfig } = this.props
    const { selectedRegion } = nextProps
    this.setState({ featureIdentifyResult: [] })
    if (nextProps.mapExplorerConfig.length > 0 || nextProps.mapExplorerConfig !== mapExplorerConfig) {
      if (nextProps.selectedRegion) {
        const region = this.getRegionById(nextProps.mapExplorerConfig, nextProps.selectedRegion.id)
        this.mapService.clearMapLayers()
        this.mapService.createLayerGroups(region)
        // render layerSwitcherPanel every update in the layer list
        // this.mapService.renderLayerSwitcherPanel('layers')
        this.mapService.addZoomToExtentControl(selectedRegion.location)
      }
    }
  }

  tileLoadStart = () => {
    this.tilesLoadingArray.push(0)
    // Prevent unnecessary renders
    if (
      this.tilesLoadingArray.length > 0 &&
      !(this.state.tilesLoading)
    ) {
      this.setState({ tilesLoading: true })
    }
  }

  tileLoadEnd = () => {
    this.tilesLoadingArray.pop()
    // Prevent unnecessary renders
    if (
      (this.tilesLoadingArray.length < 1) &&
      (this.state.tilesLoading)
    ) {
      this.setState({ tilesLoading: false })
    }
  }

  toggle = (tab) => {
    const { activeTab, map } = this.state
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab
      }, () => {
        map.updateSize()
      });
      var mapType = tab === '1' ? "interactive" : "static"
      navigate("/maps/?type=" + mapType)
    }
  }

  fetchMapExplorerConfig = locale => {
    axios(locale, "layers/map_explorer/").then(response => {
      this.props.setMapExplorerConfig(response.data)
      this.setState({ tilesLoading: false })
    })
  }

  fetchStaticMaps = () => {
    this.setState({ staticMapsLoading: true });
    var regionParam = this.props.selectedRegion.code !== "GCC" ? `?region=${this.props.selectedRegion.id}` : "";
    axios(this.props.intl.locale, "static_maps/" + regionParam).then(response => {
      const data = response.data
      this.setState({ staticMaps: data, staticMapsLoading: false })
    })
  }

  getRegionByName = (config, name) => {
    let target = null
    for (let index = 0; index < config.length; index++) {
      const region = config[index];
      if (region.name === name) {
        target = region
        break;
      }
    }
    return target
  }

  getRegionById = (config, id) => {
    let target = null
    for (let index = 0; index < config.length; index++) {
      const region = config[index];
      if (region.id === id) {
        target = region
        break;
      }
    }
    return target
  }

  nextFeature = () => {
    const { activeFeature } = this.state
    const nextIndex = activeFeature + 1
    this.setState({ activeFeature: nextIndex })
  }

  previousFeature = () => {
    const { activeFeature } = this.state
    const previousIndex = activeFeature - 1
    this.setState({ activeFeature: previousIndex })
  }

  identify = (evt) => {
    const { map } = this.state
    Promise.all(FeatureIdentify.identify(map, evt)).then(featureGroups => {
      let features = []
      for (let g = 0, gg = featureGroups.length; g < gg; g++) {
        const layers = Object.keys(featureGroups[g])
        for (let l = 0, ll = layers.length; l < ll; l++) {
          const layer = layers[l]
          let newFeatures = featureGroups[g][layer].map(f => {
            f.set('layerName', layer)
            return f
          })
          features = [...features, ...newFeatures]
        }
      }
      this.setState({
        featureIdentifyLoading: false,
        activeFeature: 0,
        featureIdentifyResult: features,
      })

    })
  }

  getCurrentFeatureLayerName = () => {
    const { featureIdentifyResult, activeFeature } = this.state
    const current = featureIdentifyResult[activeFeature]
    return current.get('layerName')
  }

  dive = (currentKey, into, target) => {
    for (var i in into) {
      if (into.hasOwnProperty(i)) {
        var newKey = i;
        var newVal = into[i];

        if (currentKey.length > 0) {
          newKey = currentKey + '.' + i;
        }

        if (typeof newVal === "object") {
          this.dive(newKey, newVal, target);
        } else {
          target[newKey] = newVal;
        }
      }
    }
  }

  flatten = (arr) => {
    var newObj = {};
    this.dive("", arr, newObj);
    return newObj;
  }

  getCurrentFeatureProps = () => {
    const { featureIdentifyResult, activeFeature } = this.state
    const current = featureIdentifyResult[activeFeature]
    let props = { ...current.getProperties() }
    delete props.layerName
    delete props.geometry
    props = this.flatten(props)

    return props
  }

  nextButtonVisible = () => {
    const { featureIdentifyResult, activeFeature } = this.state
    const nextButtonVisible = (featureIdentifyResult.length > 0 &&
      activeFeature !== featureIdentifyResult.length - 1)
    return nextButtonVisible

  }

  toggleMobileModal = () => {
    // setting featuresIdentifyResults to [] will close the feature Identify results
    if (this.state.featureIdentifyResult.length > 0)
      this.setState({
        featureIdentifyResult: [],
        mobileModalIsOpen: false,
      })
    else {
      this.setState({
        mobileModalIsOpen: !this.state.mobileModalIsOpen,
      })
    }
  }

  handlePrintMap = () => {
    this.setState({ isPrinting: true })
    printMap(this.state.map).then(response => {
      setTimeout(() => {
        window.location.assign(response.data.getURL);
      }, 100);
      this.setState({ isPrinting: false })
    }).catch(error => {
      this.setState({
        isPrinting: false,
        showErrorAlert: true
      })
      setTimeout(() => {
        this.setState({
          showErrorAlert: false
        });
      }, 4000);
    })
  }

  onDismissErrorAlert = () => this.setState({ showErrorAlert: false });

  // keys should not be displayed in the identify table
  reserved_keys = ['objectid', 'fid', 'shape', 'shape_length', 'shape_area', 'bbox.0', 'bbox.1', 'bbox.2', 'bbox.3'];
  render() {
    const { classes, selectedRegion, intl } = this.props
    const { activeTab, staticMaps, featureIdentifyLoading,
      featureIdentifyResult, activeFeature, isMobile, map,
      tilesLoading,
    } = this.state
    const rtl = intl.locale === 'ar'
    const mapLayers = map && map.getLayers().getArray()
    const layers = []
    let baseMaps = []
    mapLayers && mapLayers.forEach(l => {
      // Skip pushing 'Esri Boundaries and Places' to baseMaps as it will be pushed automatically by BaseMapSwitcher.
      if ((l.get('type') === 'base') && (l.get('title') !== 'Esri Boundaries and Places')) baseMaps = l.getLayers().getArray()
      else layers.push(l)
    })
    const LoadingSpinner = () => (
      <div id='gcc-loading-mask'>
        <img src={GCCLogo} alt="" />
      </div>
    )
    const noStaticMapsMessage = <h3><FormattedMessage id="no_maps_found" /></h3>
    const FeatureIdentifyComponent = () => (
      <div className={'identify-results'}>
        <div className={'identify-results-header'}>
          <img src={CloseLogo} alt="" onClick={() => { this.setState({ featureIdentifyResult: [] }) }} />
        </div>
        <div className={classes.propsTable}>
          <h5 className={'identify-results-layer-title'}>{this.getCurrentFeatureLayerName()}</h5>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{"Property"}</th>
                <th scope="col">{"Value"}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(this.getCurrentFeatureProps()).filter(key => !this.reserved_keys.includes(key.toLowerCase()) ).map((key, index) => {
                const props = this.getCurrentFeatureProps()
                return (<tr key={index}>
                  <td>{key}</td>
                  <td>{props[key]}</td>
                </tr>)
              })}
            </tbody>
          </table>
        </div>
        <div className={classes.tableButtons}>
          {
            rtl ?
              <React.Fragment>
                <Button onClick={this.nextFeature} disabled={!this.nextButtonVisible()}><FaArrowRight size={24} /></Button>
                <Button onClick={this.previousFeature} disabled={activeFeature !== 0 ? false : true}><FaArrowLeft size={24} /></Button>
              </React.Fragment>
              :
              <React.Fragment>
                <Button onClick={this.previousFeature} disabled={activeFeature !== 0 ? false : true}><FaArrowLeft size={24} /></Button>
                <Button onClick={this.nextFeature} disabled={!this.nextButtonVisible()}><FaArrowRight size={24} /></Button>
              </React.Fragment>
          }
        </div>
      </div>)

    const MapLargeScreen = (
      <Row>
        <Col lg={8} md={7} sm={12} className={ rtl ? 'custom-column-rtl ' : 'custom-column'}>
          <div className={'map-container'}>
            <div className={'map-wrapper'}>
              {
                tilesLoading &&
                <LoadingSpinner />
              }
              {
                featureIdentifyLoading &&
                <LoadingSpinner />
              }
              <div id="map" className={classes.mapDiv}></div>

              <button className="print-btn" onClick={this.handlePrintMap} disabled={this.state.isPrinting}>
                {this.state.isPrinting ? <Spinner size="sm" /> : <FaPrint color="white" />}
              </button>

              {baseMaps.length > 0 && <BaseMapSwitcher baseMaps={baseMaps} rtl={rtl} mapObject={this.state.map} />}
            </div>
          </div>
        </Col>
        <Col sm={12} md={5} lg={4} className="map-filter-col" style={this.props.intl.locale === "ar" ? { paddingLeft: '0px' } : { paddingRight: '0px' }}>
          <div className={'layer-list-container'}>
            {
              !(featureIdentifyResult.length > 0) &&
              <p className='layer-switcher-title'><FormattedMessage id={'available_maps_in'} /></p>
            }
            {
              layers &&
              <Switcher
                layers={layers}
                switchId={selectedRegion && selectedRegion.id}
                rtl={rtl}
                hidden={featureIdentifyResult.length > 0}
              />
            }
            {!featureIdentifyLoading && featureIdentifyResult.length > 0 && <FeatureIdentifyComponent />}
          </div>
        </Col>
      </Row>
    )
    const MapMobile = (
      <Row className="justify-content-center">
        <Col  sm={12}>
          <div className={'map-container'}>
            <div className={'map-wrapper'}>
              {
                tilesLoading &&
                <LoadingSpinner />
              }
              {
                featureIdentifyLoading &&
                <LoadingSpinner />
              }
              <div id="map" className={classes.mapDiv}></div>
              <button className="filter-btn" onClick={this.toggleMobileModal} />
              <button className="print-btn" onClick={this.handlePrintMap}
                disabled={this.state.isPrinting}>
                {this.state.isPrinting ? <Spinner size="sm" /> : <FaPrint color="white" />}
              </button>
              {baseMaps.length > 0 && <BaseMapSwitcher baseMaps={baseMaps} rtl={rtl} mapObject={this.state.map} />}
            </div>
          </div>
        </Col>
        <Col sm={12} className=" mt-4 ">
          <div className={'layer-list-container'}>
            {
              !(featureIdentifyResult.length > 0) &&
              <p className='layer-switcher-title'><FormattedMessage id={'available_maps_in'} />{selectedRegion && selectedRegion.name}</p>
            }
            {
              layers &&
              <Switcher
                layers={layers}
                switchId={selectedRegion && selectedRegion.id}
                rtl={rtl}
                hidden={featureIdentifyResult.length > 0}
              />
            }
            {!featureIdentifyLoading && featureIdentifyResult.length > 0 && <FeatureIdentifyComponent />}
          </div>
        </Col>
      </Row>
    )

    return (
      <Layout>
        <SEO title="Maps" />
        <div className="main">
          <div className="section text-center">
            <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }}>
              <Container>
                <Row>
                  <h4 className="mt-1"><FormattedMessage id="maps" /></h4>
                </Row>
              </Container>
            </div>
            <Alert color="danger" isOpen={this.state.showErrorAlert}
              toggle={this.onDismissErrorAlert} fade={true}>
              <p><FormattedMessage id="print_error_msg" /></p>
            </Alert>
            <br />
            <Container>
              <Nav tabs className={"justify-content-center maps-nav"}>
                <NavItem className={classnames({ [classes.navItem]: true, custom_nav: activeTab === '1' })}>
                  <NavLink
                    className={classnames({ active: activeTab === '1', [classes.navColor]: true })}
                    onClick={() => { this.toggle('1'); }}>
                    <h5><FormattedMessage id="interactive_maps" /></h5>
                  </NavLink>
                </NavItem>
                <NavItem className={classnames({ [classes.navItem]: true, custom_nav: activeTab === '2' })}>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                  >
                    <h5><FormattedMessage id="static_maps" /></h5>
                  </NavLink>
                </NavItem>
              </Nav>
              <br />
              <TabContent activeTab={activeTab}>
                <TabPane className="justify-content-center" tabId="1">
                  {
                    isMobile ? MapMobile : MapLargeScreen
                  }
                </TabPane>
                <TabPane tabId="2">
                  <Container>
                    <Row className="justify-content-center" >
                      {this.state.isLoading ? <Spinner />
                        : this.state.staticMaps.length === 0 ? noStaticMapsMessage
                          : staticMaps.map(mapObj => {
                            return (<Col key={mapObj.id} sm="6" lg="3">
                              <Card className="no-transition">
                                <CardImg top src={mapObj.thumbnail || NoImg} alt={mapObj.title}
                                  style={{ width: "350px", height: "200px" }} />
                                {mapObj.title ? <CardBody>
                                  <CardTitle className="map-title-parent"><h5 className="map-title-child">{mapObj.title}</h5></CardTitle>
                                </CardBody> : null}
                                <a href={mapObj.doc} target="_blank" rel="noopener noreferrer"
                                  download={mapObj.title + ".pdf"} className="btn btn-primary">
                                  <FormattedMessage id="download_doc" />
                                </a>
                              </Card>
                            </Col>)
                          })}
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Container>
          </div>

          {/** layer switcher Modal in Mobile view */}
          <Modal className="modalContainer" style={{ direction: this.props.intl.locale === "ar" ? "rtl" : "ltr" }}
            isOpen={isMobile && (!featureIdentifyLoading && featureIdentifyResult.length > 0) || this.state.mobileModalIsOpen} toggle={this.toggleMobileModal}>
            <ModalBody>
              <div className={'layer-list-container'}>
                {
                  !(featureIdentifyResult.length > 0) &&
                  <p className='layer-switcher-title'><FormattedMessage id={'available_maps_in'} />{selectedRegion && selectedRegion.name}</p>
                }
                {
                  layers &&
                  <Switcher
                    layers={layers}
                    switchId={selectedRegion && selectedRegion.id}
                    rtl={intl.locale === 'ar'}
                    hidden={featureIdentifyResult.length > 0} />
                }
                {!featureIdentifyLoading && featureIdentifyResult.length > 0 && <FeatureIdentifyComponent />}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggleMobileModal}>OK</Button>{' '}
            </ModalFooter>
          </Modal>

        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    mapExplorerConfig: state.mapExplorerConfig,
    selectedRegion: state.regions.selectedRegion,
    regions: state.regions.regions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMapExplorerConfig: config => dispatch(actions.setMapExplorerConfig(config)),
    setSelectedRegion: regionCode => dispatch(actions.setSelectedRegion(regionCode)),
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(injectIntl(MapsPage)))
