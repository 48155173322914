import axios from "axios"

export default function printMap(map) {
    var printSpecs = {
        units: "ft",
        srs: map.getView().getProjection().getCode(),
        mapTitle: "",
        comment: "",
        layout: "A4_landscape",
        outputFormat: "pdf",
        layers: [],
        legends: [],
        pages: [
            {
                dpi: 150,
                srs: map.getView().getProjection().getCode(),
                geodetic: false,
                strictEpsg4326: false,
                bbox: map.getView().calculateExtent(),
                rotation: 0
            }
        ],
    }

    var resolutions = [156543.03390625, 78271.516953125, 39135.7584765625, 19567.87923828125, 9783.939619140625, 4891.9698095703125, 2445.9849047851562, 1222.9924523925781, 611.4962261962891, 305.74811309814453, 152.87405654907226, 76.43702827453613, 38.218514137268066, 19.109257068634033, 9.554628534317017, 4.777314267158508, 2.388657133579254, 1.194328566789627, 0.5971642833948135, 0.29858214169740677, 0.14929107084870338, 0.07464553542435169, 0.037322767712175846, 0.018661383856087923, 0.009330691928043961, 0.004665345964021981, 0.0023326729820109904, 0.0011663364910054952]

    var visibleLayers = []
    var legends = [];

    //insert the other visible layers
    const mapLayers = map && map.getLayers().getArray()
    mapLayers && mapLayers.forEach(layerGroup => {
        layerGroup.get('layers').forEach(layer => {
            if (layer.get('visible') === true) {
                if (layer.get('type') === 'base') {
                    let layerJson;
                    var baseMapName = layer.get('title')
                    switch (baseMapName) {
                        case 'OpenStreetMap':
                            layerJson = {
                                baseURL: "http://a.tile.openstreetmap.org",
                                maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                                tileSize: [256, 256],
                                extension: "png",
                                resolutions: resolutions,
                                opacity: 1,
                                type: "OSM"
                            }
                            break;
                        case 'Stamen WaterColor Map':
                            layerJson = {
                                baseURL: "https://stamen-tiles-a.a.ssl.fastly.net/watercolor",
                                maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                                tileSize: [256, 256],
                                extension: "png",
                                resolutions: resolutions,
                                opacity: 1,
                                type: "OSM"
                            }
                            break;
                        case 'Stamen Toner Map':
                            layerJson = {
                                baseURL: "https://stamen-tiles-a.a.ssl.fastly.net/toner",
                                maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                                tileSize: [256, 256],
                                extension: "png",
                                resolutions: resolutions,
                                opacity: 1,
                                type: "OSM"
                            }
                            break;
                        case 'Stamen Terrain Map':
                            layerJson = {
                                baseURL: "https://stamen-tiles-b.a.ssl.fastly.net/terrain",
                                maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                                tileSize: [256, 256],
                                extension: "png",
                                resolutions: resolutions,
                                opacity: 1,
                                type: "OSM"
                            }
                            break;
                        case 'Esri Topo Map':
                            layerJson = {
                                baseURL: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile",
                                maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                                tileSize: [256, 256],
                                extension: "png",
                                resolutions: resolutions,
                                tileOriginCorner: "bl",
                                path_format: "${z}/${y}/${x}",
                                tileOrigin: {
                                    "x": -20037508.34,
                                    "y": -20037508.34
                                },
                                opacity: 1,
                                type: "XYZ"
                            }
                            break;
                        case 'Esri Imagery Hybrid':
                            layerJson = [{
                                baseURL: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile",
                                maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                                tileSize: [256, 256],
                                extension: "png",
                                resolutions: resolutions,
                                tileOriginCorner: "bl",
                                path_format: "${z}/${y}/${x}",
                                tileOrigin: {
                                    "x": -20037508.34,
                                    "y": -20037508.34
                                },
                                opacity: 1,
                                type: "XYZ"
                            },{
                                baseURL: "https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile",
                                maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                                tileSize: [256, 256],
                                extension: "png",
                                resolutions: resolutions,
                                tileOriginCorner: "bl",
                                path_format: "${z}/${y}/${x}",
                                tileOrigin: {
                                    "x": -20037508.34,
                                    "y": -20037508.34
                                },
                                opacity: 1,
                                type: "XYZ"
                            }]
                            break;
                        default:
                            break;
                    }
                    if(Array.isArray(layerJson))
                        visibleLayers.push(...layerJson)
                    else
                        visibleLayers.push(layerJson)
                } else {
                    var options = layer.get('metadata').options
                    var type = layer.get('metadata').type
                    let layerJson;
                    switch (type) {
                        case 'WMS':
                            layerJson = {
                                baseURL: layer.get('metadata').url,
                                tileSize: [256, 256],
                                extension: "png",
                                layers: [options.layers],
                                format: "image/png",
                                type: "WMS",
                                opacity: 1
                            }
                            visibleLayers.push(layerJson);
                            var legendObj = {
                                name: "",
                                classes: [
                                    {
                                        name: layer.get('metadata').name_en,
                                        icons: [layer.get('legendUrl')]
                                    }
                                ]
                            }
                            legends.push(legendObj)
                            break;

                        case 'Tile':
                            layerJson = {
                                baseURL: layer.get('metadata').url,
                                opacity: 1,
                                maxExtent: [-20037508.34, -20037508.34, 20037508.34, 20037508.34],
                                extension: "png",
                                tileOriginCorner: "bl",
                                path_format: "${z}/${y}/${x}",
                                tileOrigin: {
                                    "x": -20037508.34,
                                    "y": -20037508.34
                                },
                                resolutions: resolutions,
                                tileSize: [256, 256],
                                type: "XYZ"
                            };
                            visibleLayers.push(layerJson);
                            var legendObj = {
                                name: "",
                                classes: [
                                    {
                                        name: layer.get('metadata').name_en,
                                        icons: [layer.get('legendUrl')]
                                    }
                                ]
                            }
                            legends.push(legendObj)
                            break;
                        default:
                            break;
                    }


                }
            }
        })
    })
    printSpecs['layers'] = visibleLayers
    printSpecs['legends'] = legends

    const baseURL = window.location.protocol.concat("//").concat(window.location.hostname).concat("/");
    return axios.post(baseURL + "geoserver/pdf/create.json", printSpecs)
}