import React, {useState} from "react"
import "../../assets/css/base-map-switcher.css"

const BaseMapsList = props => {
  const { baseMaps, onMouseOver, onMouseOut, onClick } = props
  // // Remove "Esri Boundaries and Places" basemap from switcher list
  // const updatedBaseMaps = baseMaps.filter(baseMap => baseMap.get("title") !== "Esri Boundaries and Places");
  return baseMaps.map((map, i) => {
    return (
      <div className={"base-map-selector"} key={i}>
        <div className={"image-wrapper"}>
          <img
            src={map.get('iconSrc')}
            alt=""
            onMouseOver={() => {
              onMouseOver(map)
            }}
            onMouseOut={onMouseOut}
            onClick={() => { onClick(map) }}
          />
        </div>
      </div>
    )
  })
}
const SelectedBaseMap = props => {
  const { open, setOpen, rtl, map } = props
  return (
    <div
      className={open ? "base-map-selector-open" : "base-map-selector"}
      onClick={() => {
        setOpen(!open)
      }}
    >
      <div className={"image-wrapper"}>
        {
          rtl &&
          <React.Fragment>
            <img src={map.get('iconSrc')} alt="" />
            {open && <p className={"base-map-title"}>{map.get('title')}</p>}
          </React.Fragment>
        }
        {
          !rtl &&
          <React.Fragment>
            {open && <p className={"base-map-title"}>{map.get('title')}</p>}
            <img src={map.get('iconSrc')} alt="" />
          </React.Fragment>
        }
      </div>
    </div>
  )
}
const BaseMapSwitcher = props => {
  const {
    baseMaps,
    rtl
  } = props
  const getSelectedBaseMap = (baseMaps) => {
    let map = undefined
    baseMaps.forEach(m => {
      m.getVisible() && (map = m)
    });
    if (map !== undefined)
      return map
    else
      // return the first base map and let the user select 
      return baseMaps[0]
  }
  const initialSelected = getSelectedBaseMap(baseMaps)
  const [open, setOpen] = useState(true)
  const [selected, setSelected] = useState(initialSelected)
  const [oldSelection, setOldSelection] = useState(initialSelected)
  const onMouseOver = map => {
    setOldSelection(selected)
    setSelected(map)
  }
  const onMouseOut = () => {
    setSelected(oldSelection)
  }


  const onClick = (map) => {
    baseMaps.forEach(m => { m.setVisible(false) });
    map.setVisible(true)
    setSelected(map)
    setOldSelection(selected)
  }
  return (
    <div className="base-map-switcher">
      {
        rtl &&
        <React.Fragment>
          {open && (
            <BaseMapsList
              baseMaps={baseMaps}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              setSelected={setSelected}
              onClick={onClick}
            />
          )}
          <SelectedBaseMap open={open} setOpen={setOpen} map={selected} rtl={rtl} />
        </React.Fragment>
      }
      {
        !rtl &&
        <React.Fragment>
          <SelectedBaseMap open={open} setOpen={setOpen} map={selected} rtl={rtl} />
          {open && (
            <BaseMapsList
              baseMaps={baseMaps}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              setSelected={setSelected}
              onClick={onClick}
            />
          )}
        </React.Fragment>
      }
    </div>
  )
}
export default BaseMapSwitcher
