import EsriJSON from 'ol/format/EsriJSON';
import GeoJSON from 'ol/format/GeoJSON'
import Group from 'ol/layer/Group'
import ImageWMS from 'ol/source/ImageWMS'
import TileArcGISRest from 'ol/source/TileArcGISRest';
import TileWMS from 'ol/source/TileWMS'
import axios from 'axios'
import fetchJsonp from 'fetch-jsonp'
import proj4 from 'proj4'
const geojsonFormat = new GeoJSON()
class FeatureIdentify {
	getCRS(crs) {
		let promise = new Promise((resolve, reject) => {
			if (proj4.defs('EPSG:' + crs)) {
				resolve(crs)
			} else {
				axios.get(`https://epsg.io/?format=json&q=${crs}`).then(
					response => {
						proj4.defs('EPSG:' + crs, response.data.results[0].proj4)
						resolve(crs)
					}).catch(err => { reject(err) })
			}
		})
		return promise
	}
	isWMSLayer(layer) {
		const source = layer.getSource()
		return source instanceof TileWMS || source instanceof ImageWMS
	}
	getLayers(mapLayers) {
		let children = []
		mapLayers.forEach((layer) => {
			if (layer instanceof Group) {
				children = children.concat(this.getLayers(layer.getLayers()))
			} else if (layer.getVisible() && this.isWMSLayer(
				layer)) {
				children.push(layer)
			}
		})
		return children
	}
	getArcGISLayers(mapLayers) {
		let children = []
		mapLayers.forEach((layer) => {
			const layerMetadata = layer.get('metadata')
			const isGroup = layer instanceof Group
			let source = null
			if (!isGroup) {
				source = layer.getSource()
			}
			if (isGroup) {
				children = children.concat(this.getArcGISLayers(layer.getLayers()))
			} else if (layer.getVisible() && ((layerMetadata && layerMetadata.type === "AGS") || source instanceof TileArcGISRest)) {
				children.push(layer)
			}
		})
		return children
	}
	identify(map, evt) {
		const view = map.getView()
		const mapResolution = view.getResolution()
		const mapProjection = view.getProjection()
		const mapLayers = map.getLayers().getArray()
		const wmsLayers = this.getLayers(mapLayers).reverse()
		let agsLayers = this.getArcGISLayers(mapLayers).reverse()
		let agsPromises = agsLayers.map(agsLayer => {
			const metadata = agsLayer.get('metadata')
			let identifyPromiseHandler = new Promise((resolve, reject) => {
				let url = metadata.url;
				let params = {
					geometry: evt.coordinate.join(","),
					sr: 102100,
					tolerance: 2,
					layers: "visible",
					f: 'json',
					imageDisplay: map.getSize().join(",") + ",96",
					mapExtent: view.calculateExtent(map.getSize()).join(",")
				};
				if (url.endsWith("/")) {
					url = url.substring(0, url.length - 1);
				}
				if (!url.toLowerCase().endsWith("mapserver")) {
					let urlParts = url.split("/");
					params.layers = "all:" + urlParts.pop();
					url = urlParts.join("/");
				}

				url += "/identify";
				url = new URL(url);
				const keys = Object.keys(params)
				for (let index = 0; index < keys.length; index++) {
					const key = keys[index];
					url.searchParams.set(key, params[key])

				}
				// jsonp(url.toString(), { name: 'JSON_CALLBACK' }, (err, data) => {
				// 	console.log(data, err);
				// 	if (err) {
				// 		resolve({ [metadata.name]: [] })
				// 	}
				// 	if (!data || data.results.length == 0) {
				// 		resolve({ [metadata.name]: [] })
				// 	}
				// 	let detectedFeautres = []
				// 	let esriFormat = new EsriJSON()
				// 	for (let index = 0; index < data.results.length; index++) {
				// 		let f = data.results[index];
				// 		f = esriFormat.readFeature(f);
				// 		detectedFeautres.push(f)

				// 	}
				// 	resolve({ [metadata.name]: detectedFeautres })
				// })
				fetchJsonp(url.toString()).then((response) => {
					return response.json()
				}).then((json) => {
					let data = json
					if (!data || data.results.length === 0) {
						resolve({ [metadata.name]: [] })
					}
					let detectedFeautres = []
					let esriFormat = new EsriJSON()
					for (let index = 0; index < data.results.length; index++) {
						let f = data.results[index];
						f = esriFormat.readFeature(f);
						detectedFeautres.push(f)

					}
					resolve({ [metadata.name]: detectedFeautres })
				}).catch((ex) => {
					resolve({ [metadata.name]: [] })
				})
				// jsonp(url.toString()).then(response => {
				// 	const data = response.data
				// 	if (data.results.length == 0) {
				// 		resolve({ [metadata.name]: [] })
				// 	}
				// 	let detectedFeautres = []
				// 	let esriFormat = new EsriJSON()
				// 	for (let index = 0; index < data.results.length; index++) {
				// 		let f = data.results[index];
				// 		f = esriFormat.readFeature(f);
				// 		detectedFeautres.push(f)

				// 	}
				// 	resolve({ [metadata.name]: detectedFeautres })

				// }).catch(err => {
				// 	console.error(`Layer ${metadata.name} => Feature Identify Error:`, err)
				// 	resolve({ [metadata.name]: [] })
				// })
			})
			return identifyPromiseHandler
		})
		const wfsPromise = new Promise((resolve) => {
			const layerFeatures = {}
			map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
				const metadata = layer.get('metadata')
				const layer_name = metadata['name']
				if (layerFeatures[layer_name] === undefined) {
					layerFeatures[layer_name] = []
				}
				layerFeatures[layer_name].push(feature)
			})
			resolve(layerFeatures)
		})
		let identifyPromises = wmsLayers.map(
			(layer) => {
				const metadata = layer.get('metadata')
				const layerName = metadata.name
				let identifyPromiseHandler = new Promise((resolve, reject) => {
					const source = layer.getSource()
					const url = source.getGetFeatureInfoUrl(
						evt.coordinate, mapResolution, mapProjection, {
							"INFO_FORMAT": 'application/json',
							"FEATURE_COUNT": 10
						},
					)
					axios.get(url).then(response => {
						const data = response.data
						if (data.features && data.features.length > 0) {
							const crs = data.features.length > 0 ?
								data.crs.properties.name.split(":").pop() : null
							this.getCRS(crs).then(newCRS => {
								const features = geojsonFormat.readFeatures(data)
								resolve({ [metadata.name]: features })
							}, (error) => {
								reject(error)
							})
						} else {
							resolve({ [layerName]: [] })
						}
					}).catch(err => {
						console.error(`Layer ${layerName} => Feature Identify Error:`, err)
						resolve({ [layerName]: [] })
					})
				})
				return identifyPromiseHandler
			})
		return [wfsPromise, ...identifyPromises, ...agsPromises]
	}

}
export default new FeatureIdentify()