import React, { useState, useCallback } from 'react'
import RightArrow from '../../assets/img/arrow_heads/right-arrow.png'
import LeftArrow from '../../assets/img/arrow_heads/left-arrow.png'
import DownArrow from '../../assets/img/arrow_heads/down-arrow.png'
import '../../assets/css/layer-switcher.css'
import fetchAGSLegend from '../../utils/fetchAgsLegend'

const WMSLegendComponent = props => {
    const {
        url
    } = props
    return <div className={'wms-legend-item'}>
        <img src={url} alt="" />
    </div>
}
const AGSLegendComponent = props => {
    // returns component with AGS legend
    const {
        legendArray
    } = props
    return <div className={'layer-item-legend'}>
        {
            legendArray.length > 0 && legendArray.map(
                (l, i) => {
                    return(
                        <div key={i} className={'ags-legend-item'}>
                            <img src={"data:image/gif;base64,"+l.imageData} alt="" />
                            <label htmlFor="">{l.label}</label>
                        </div>
                    )
                }
            )
        }
    </div>
}
const LayerItem = props => {
    const {
        onChange,
        layer,
        updateParentGroup,
        rtl
    } = props

    const [collapsed, setCollapsed] = useState(true)
    const [legendArray, setLegendArray] = useState([])

    // force update and use layer.getVisible() instead
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const onCollapse = () => {
        setCollapsed(!collapsed)
        if(layer.get('type') === 'AGS'){
            layer.get('legend').then(legendArray=>{
                // if legend array resolved while initializing the ol map
                if(legendArray && legendArray.length > 0)
                    setLegendArray(legendArray)
                else{
                    // try to fetch legend if failed while initializing
                    fetchAGSLegend(layer.get('legendUrl'), layer.get('AGSLayerId'))
                    .then(legendArray=>{
                        setLegendArray(legendArray)
                    })
                }
            })
        }
    }

    return (
        <div className={'layer-item'}>
            <div className={'layer-item-head'} key={layer.get('ol_uid')}>
                {
                    collapsed ?
                        rtl ?
                            <img src={LeftArrow} alt="" onClick={onCollapse} /> :
                            <img src={RightArrow} alt="" onClick={onCollapse} />
                        :
                        <img src={DownArrow} alt="" onClick={() => { setCollapsed(true) }} />
                }
                <div className={'checkbox-wrapper'}>
                    <input className={'layer-checkbox-input'}
                        type="checkbox"
                        checked={layer.getVisible()} onChange={() => { onChange(layer); forceUpdate(); updateParentGroup && updateParentGroup() }} />
                </div>
                <label htmlFor="" onClick={onCollapse}>{layer.get('title')}</label>
            </div>
            {
                !collapsed &&
                layer.get('type') === 'WMS' &&
                <WMSLegendComponent url={layer.get('legendUrl')} />
            }
            {
                !collapsed &&
                layer.get('type') === 'AGS' &&
                <AGSLegendComponent legendArray={legendArray} />
            }
        </div>
    )
}
const GroupItem = props => {
    const {
        title,
        layers,
        onChange,
        rtl,
        iconUrl,
    } = props
    const areSomeChecked = (layers) => {
        let allHidden = true
        for (let l of layers) {
            if (l.getVisible()) {
                allHidden = false
                break
            }
        }
        return !allHidden
    }
    const [collapsed, setCollapsed] = useState(true)
    const [allChecked, setAllChecked] = useState(areSomeChecked(layers))

    // force refresh / re-render method
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => {
        setAllChecked(areSomeChecked(layers))
        updateState({})
    }, [layers]);

    const onCheckAll = (e, lyrs) => {
        const checked = e.target.checked
        lyrs.forEach(l => {
            if (l.getLayers)
                onCheckAll(e, l.getLayers().getArray())
            else
                l.setVisible(checked)
        })
        forceUpdate()
    }
    return (
        <div className={'layer-group'}>
            <div className={'layer-item-head'}>
                {
                    collapsed ?
                        rtl ?
                            <img src={LeftArrow} alt="" onClick={() => { setCollapsed(false) }} /> :
                            <img src={RightArrow} alt="" onClick={() => { setCollapsed(false) }} />
                        :
                        <img src={DownArrow} alt="" onClick={() => { setCollapsed(true) }} />
                }
                <input type="checkbox" onChange={(e) => { onCheckAll(e, layers) }} checked={allChecked} />
                {
                    iconUrl &&
                    <img className={'group-icon'} src={iconUrl} alt="" srcset=""/>
                }
                <label className={'group-title'} onClick={() => { setCollapsed(!collapsed) }}>{title}</label>
            </div>
            {
                !collapsed &&
                <div className={'layer-list'}>
                    {
                        layers.map((l, i) => {
                            if (l.getLayers) {
                                return (
                                    <GroupItem
                                        key={i}
                                        title={l.get('title')}
                                        layers={l.getLayersArray()}
                                        onChange={onChange}
                                        rtl={rtl}
                                    />
                                )
                            }
                            return (
                                <LayerItem
                                    key={i}
                                    onChange={onChange}
                                    layer={l}
                                    updateParentGroup={forceUpdate}
                                    rtl={rtl}
                                />
                            )
                        }
                        )
                    }
                </div>
            }
        </div>
    )
}
const Switcher = (props) => {
    const {
        layers,
        switchId,
        rtl,
        hidden
    } = props
    const onLayerSetVisible = l => {
        l.setVisible(!l.getVisible())
    }
    return (
        <div className={hidden ? 'switcher-hidden' : ''}>
            {
                layers.map((l) => {
                    if (l.getLayers)
                        return (
                            <GroupItem
                                // creating unique KEY so that react does not render the component again
                                // layerId key is set while map service creates map layers in openlayers map
                                key={l.get('layerId') * switchId}
                                title={l.get('title')}
                                layers={l.getLayersArray()}
                                onChange={onLayerSetVisible}
                                rtl={rtl}
                                iconUrl={l.get('iconUrl')}
                            />
                        )
                    else
                        return <LayerItem
                            onChange={onLayerSetVisible}
                            layer={l}
                            rtl={rtl}
                        />
                })
            }
        </div>
    )
}
export default Switcher;